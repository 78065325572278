import { createEnvironment } from "@spear-ai/relay-environment";
import React, { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";

const apiUrl = "/api/graphql";

export const RelayProvider = (properties: { children: JSX.Element }) => {
  const { children } = properties;
  const environment = useMemo(() => createEnvironment({ apiUrl }), []);

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};
