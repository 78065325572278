"use client";

import { useRouter } from "next/navigation";
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";
import { I18nProvider } from "react-aria";
import { RouterProvider } from "react-aria-components";
import { IntlProvider } from "react-intl";
import { RelayProvider } from "@/components/relay-provider/relay-provider";
import { messagesByLocale } from "@/messages/messages";

export const AppProviders = (properties: { children: ReactNode; locale: string }) => {
  const { children, locale } = properties;
  const router = useRouter();
  const messages = messagesByLocale[locale as keyof typeof messagesByLocale];

  return (
    <I18nProvider locale={locale}>
      <IntlProvider locale={locale} messages={messages}>
        <RelayProvider>
          <ThemeProvider attribute="class" defaultTheme="dark">
            <RouterProvider
              navigate={(...arguments_) => {
                router.push(...arguments_);
              }}
            >
              {children}
            </RouterProvider>
          </ThemeProvider>
        </RelayProvider>
      </IntlProvider>
    </I18nProvider>
  );
};
